import { Grommet, Box, Heading } from 'grommet';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

import AssistantCard from '../../../components/assistant-card/assistant-card';
import { usePlatformMutation } from '../../../hooks';
import { isSSR } from '../../../utils';
import theme from '../../../theme';

const GoogleCseDemo = () => {
  const [result, setResult] = useState(null);

  const searchMutation = usePlatformMutation('/botchat', {
    method: 'POST',
  });

  const onSearch = (query) => {
    if (!query) return;

    searchMutation.mutate(
      { query },
      {
        onSuccess: (response) => {
          setResult(response.Data.result);
        },
      },
    );
  };

  if (!isSSR) {
    window.__gcse || (window.__gcse = { parsetags: 'onload' });
    window.__gcse.searchCallbacks = {
      web: {
        starting: (_layout, searchTerm) => onSearch(searchTerm),
      },
    };

    const container = document.getElementById('pageContainer');

    if (container) container.style.backgroundColor = 'rgba(255, 255, 255, 1)';
  }

  return (
    <>
      <Helmet>
        <script async src="https://cse.google.com/cse.js?cx=002518004963485979666:hk46osz46bx"></script>
      </Helmet>
      <Grommet theme={theme} themeMode="light">
        <Box align="center" margin="small">
          <Heading size="small" margin="medium" color="black">
            Google Custom Search Sample
          </Heading>
          <Box width="large">
            <div className="gcse-searchbox"></div>
            <Box>
              {result?.card && (
                <Box pad="small">
                  <Box fill="horizontal" style={{ maxWidth: '500px' }}>
                    <AssistantCard metadata={result.card} />
                  </Box>
                </Box>
              )}
            </Box>
            <div className="gcse-searchresults"></div>
          </Box>
        </Box>
      </Grommet>
    </>
  );
};

export default GoogleCseDemo;
