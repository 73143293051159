import { memo, useCallback, useEffect } from 'react';
import { dispatch } from 'use-bus';

import { Router, navigate } from '@reach/router';

import config, { apiUrl, defaultFetchOpts } from '../../config';
import { useSessionStore, useToken } from '../../hooks';

const opts = defaultFetchOpts;

const PrivateRoute = ({ children, ...rest }) => {
  const setSession = useSessionStore((state) => state.setSession);
  const { access_token } = useToken();

  if (config.useTokenAuth) {
    opts.headers['Authorization'] = `Bearer ${access_token}`;
  } else {
    opts.credentials = 'include';
  }

  const checkAuthentication = useCallback(async () => {
    const response = await fetch(`${apiUrl}/session/myprofile`, opts);
    const data = await response.json();

    if (config.useTokenAuth) {
      if (!access_token) return;

      if (!data.Data?.authenticated) {
        dispatch('pkce.clear');
        return;
      }

      setSession(data.Data);
      return;
    }

    if (data.Data?.authenticated) {
      setSession(data.Data);
      return;
    }

    if (config.oidcProvider) {
      navigate(
        `${config.hostUrl}/oauth2connector/signin/${config.oidcProvider}?returnUrl=${encodeURIComponent(window.location.href)}${config.tenantId ? `&tid=${config.tenantId}` : ''}`,
      );

      return;
    }

    navigate(`${config.hostUrl}/account/login?returnUrl=${encodeURIComponent(window.location.href)}${config.tenantId ? `&tid=${config.tenantId}` : ''}`);
  }, [access_token, setSession]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return <Router {...rest}>{children}</Router>;
};

export default memo(PrivateRoute);
