import './splash.css';

import loading from './loading.svg';

const Splash = () => {
  return (
    <>
      <div id="splash">
        <div className="initial-load-animation">
          <div
            className="assistant-image"
            style={{
              backgroundImage: `url(${loading})`,
            }}
          ></div>
          <div className="loading-bar">
            <div className="color-bar"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;
